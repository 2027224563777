.real_name_desginer{
  height: 42vw;
  margin: 0 -5.208vw;
  margin-top: 6.354vw;
  display: flex;
  .bar{
    width: 2.604vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-of-type{
      margin-right: 2.604vw;
    }
    &:last-of-type{
      margin-left: 2.604vw;
    }
    & > img{
      width: 2.604vw;
      height: 5.208vw;
      cursor: pointer;
      transition: all .5s;
      transform: translateY(-50%);
      &:hover{
        transform: translateY(-50%) scale(1.5);
      }
    }
  }

  .swiper_box{
    padding-top: 2.604vw;
    flex: 1;
    .swiper_item{
      width: 20.833vw;
      //width: 21.875vw;

      .wutai {
        height: 100%;
        &:hover .wutai_box .card{
          transform: rotateY(180deg);
        }
        .wutai_box{
          margin: 0 auto;
          height: 33.417vw;
          margin-bottom: 14.0625vw;
          /* 设置舞台距离 */
          perspective: 1200px;
          //&:hover {
          //  .card {
          //    transform: rotateY(180deg);
          //  }
          //}
          .card{
            width: 100%;
            height: 100%;
            transition: .6s;
            transform: translateZ(0px);
            position: relative;
            /* 开启3d */
            transform-style: preserve-3d;
            -moz-backface-visibility:hidden; /* Firefox */
            padding-bottom: 100px;

            & > div{
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              backface-visibility: hidden;
              -webkit-backface-visibility:hidden; /* Chrome 、Safari */
              -moz-backface-visibility:hidden; /* Firefox */
              -ms-backface-visibility:hidden; /* Internet Explorer */
              & > img {
                display: block;
                width: 100%;
              }

              & > .name {
                font-size: 1.5625vw;
                font-weight: 300;
                color: #FFFFFF !important;
                line-height: 1;
                margin-top: 1.042vw;
                text-align: center;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                  display: inline-block;
                  width: 0.417vw;
                  height: 0.521vw;
                  vertical-align: middle;
                  &:first-of-type {
                    display: none;
                  }
                  &:last-of-type {
                    display: inline-block;
                  }
                }
                &:hover {
                  color: #FC296B !important;
                  & img {
                    &:first-of-type {
                      display: inline-block;
                    }
                    &:last-of-type {
                      display: none;
                    }
                  }
                }
              }
            }

            .back{
              transform: rotateY(180deg);
            }
            .face{
              //background-color: blueviolet;
            }
          }

        }
      }
    }
  }

}